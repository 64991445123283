import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nicu-dashboard',
  templateUrl: './nicu-dashboard.component.html',
  styleUrls: ['./nicu-dashboard.component.css']
})
export class NicuDashboardComponent implements OnInit {
  iterate_data : any[] = [];

  sampleBabyData: { access_token: string, babyLiveData: any[] } = {
    access_token: 'zxc321',
    babyLiveData: [
      { id: 1, name: 'xyz-1' },
      { id: 2, name: 'xyz-2' },
      { id: 3, name: 'xyz-3' },
      { id: 4, name: 'xyz-4' },
      { id: 5, name: 'xyz-5' },
      { id: 6, name: 'xyz-6' },
      { id: 7, name: 'xyz-7' }
    ]
  }
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const auth_access_token = this.route.snapshot.paramMap.get('auth_token');
    console.log(`Access token ${auth_access_token}`);

    if (this.sampleBabyData.access_token === auth_access_token){
      this.iterate_data = this.sampleBabyData.babyLiveData;
    }
  }


}
