<p>NICU Dashboard</p>
<br>

<div class="row">
    <div class="col-sm-4 m-1 border" *ngFor="let item of iterate_data">
        ID : {{item.id }}
        <br>
        Name : {{item.name}}
    </div>

    <div class="col-sm-12" *ngIf="iterate_data.length ===0">
        No data !
    </div>
</div>
